import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

function truncate (string, length) {
  if (string.length > length) {
    return string.substr(0, length) + '...'
  } else {
    return string
  }
}

const TPMGraphLabel = ({ x, y, payload: { value: month } }) => {
  if (!month) {
    return null
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={8} textAnchor='start' fill='#666' transform='rotate(66)'>
        {truncate(moment(month).format('MMM YYYY'), 20)}
      </text>
    </g>
  )
}

TPMGraphLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object
}

export default TPMGraphLabel
