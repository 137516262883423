import React from 'react'
import { selectReports } from 'reports/tpm/selectors'
import { injectIntl, intlShape } from 'react-intl'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Tooltip
} from 'recharts'
import moment from 'moment'
import TPMGraphLabel from './TPMGraphLabel'

const BAR_SIZE = 40

const monthFormatter = (month) => moment(month).format('MMM YYYY')

const GraphTooltip = ({ payload }) => {
  if (!payload || !payload[0]) {
    return null
  }

  const { month, mtbf } = payload[0].payload

  return (
    <div className='report-graph-tooltip'>
      <div className='report-graph-tooltip__content'>
        <h4 className='report-graph-tooltip__header'>
          {monthFormatter(month)}
        </h4>
      </div>
      <div className='report-graph-tooltip__footer'>
        <div style={{ display: 'flex' }}>
          <div className='recharts-custom-tooltip__value'>
            {mtbf.toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  )
}

GraphTooltip.propTypes = {
  payload: PropTypes.array
}

const TPMGraphMTBF = ({ data, intl }) => {
  return (
    <div>
      <h2>{intl.formatMessage({ id: 'reports.tpm.mtbf' })}</h2>
      <div style={{ width: '100%', height: 500, fontFamily: 'monospace' }}>
        <ResponsiveContainer>
          <ComposedChart
            syncId='tpm'
            width={600}
            height={600}
            data={data}
            margin={{
              top: 20,
              right: 20,
              bottom: 120,
              left: 20
            }}
            barGap={0}
          >
            <CartesianGrid stroke='#f5f5f5' />
            <ReferenceLine y={0} stroke='#000' />
            <XAxis
              dataKey='month'
              interval={0}
              tick={<TPMGraphLabel />}
            />
            <YAxis dataKey='mtbf' />
            <Bar dataKey='mtbf' barSize={BAR_SIZE} fill='#FFB1A1' />
            <Tooltip content={<GraphTooltip />} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

TPMGraphMTBF.propTypes = {
  data: PropTypes.array,
  intl: intlShape
}

const mapStateToProps = (state) => ({
  data: selectReports(state)
})

export default compose(connect(mapStateToProps), injectIntl)(TPMGraphMTBF)
