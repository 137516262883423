import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loader from 'components/Loader'
import TPMReportHeader from './TPMReportHeader'
import TPMReportLocations from './TPMReportLocations'
import TPMGraphMARDT from './TPMGraphMARDT'
import TPMGraphMTTR from './TPMGraphMTTR'
import TPMGraphMTBF from './TPMGraphMTBF'
import TPMTable from './TPMTable'

const TPMReport = ({ isLoaded }) => {
  if (isLoaded) {
    return (
      <React.Fragment>
        <TPMReportHeader />
        <TPMReportLocations />
        <TPMGraphMARDT />
        <TPMGraphMTTR />
        <TPMGraphMTBF />
        <TPMTable />
      </React.Fragment>
    )
  } else {
    return <Loader full />
  }
}

TPMReport.propTypes = {
  isLoaded: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isLoaded: state.init.isFinished
})

export default connect(mapStateToProps)(TPMReport)
