import React from 'react'
import { selectReports } from 'reports/tpm/selectors'
import { injectIntl, intlShape } from 'react-intl'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

const monthFormatter = (month) => moment(month).format('MMM YYYY')

const TPMTable = ({ data, intl }) => {
  if (!data) return null

  return (
    <div>
      <div style={{ width: '100%', fontFamily: 'monospace' }}>
        <table className='table'>
          <thead>
            <tr>
              <th>{intl.formatMessage({ id: 'reports.tpm.table.month' })}</th>
              <th>
                {intl.formatMessage({
                  id: 'reports.tpm.table.stoppageDuration'
                })}
              </th>
              <th>{intl.formatMessage({ id: 'reports.tpm.table.mechanical' })}</th>
              <th>{intl.formatMessage({ id: 'reports.tpm.table.technical' })}</th>
              <th>{intl.formatMessage({ id: 'reports.tpm.table.electrical' })}</th>
              <th>
                {intl.formatMessage({ id: 'reports.tpm.table.stoppageCount' })}
              </th>
              <th>
                {intl.formatMessage({
                  id: 'reports.tpm.table.productionDuration'
                })}
              </th>
              <th>{intl.formatMessage({ id: 'reports.tpm.table.mttr' })}</th>
              <th>{intl.formatMessage({ id: 'reports.tpm.table.mtbf' })}</th>
              <th>
                {intl.formatMessage({ id: 'reports.tpm.table.mardtResult' })}
              </th>
              <th>
                {intl.formatMessage({ id: 'reports.tpm.table.mardtTarget' })}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((v) => (
              <tr key={v.month}>
                <td style={{ minWidth: 100 }}>{monthFormatter(v.month)}</td>
                <td>{v.stoppageDuration.toFixed(2)}</td>
                <td>{v.mechanical.toFixed(2)}</td>
                <td>{v.technical.toFixed(2)}</td>
                <td>{v.electrical.toFixed(2)}</td>
                <td>{v.stoppageCount}</td>
                <td>{v.productionDuration.toFixed(2)}</td>
                <td>{v.mttr.toFixed(2)}</td>
                <td>{v.mtbf.toFixed(2)}</td>
                <td>{v.mardt.toFixed(2)}</td>
                <td>{v.threshold}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

TPMTable.propTypes = {
  data: PropTypes.array,
  intl: intlShape
}

const mapStateToProps = (state) => ({
  data: selectReports(state)
})

export default compose(connect(mapStateToProps), injectIntl)(TPMTable)
