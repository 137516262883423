import { createSelector } from 'reselect'

export const selectToken = state => state.config.token
export const selectTimezone = state => state.config.timezone

export const selectReports = state => state.reports

export const selectVisibleLocationIds = state =>
  state.locations.allIds.filter(id => state.locations.isVisibleById[id])

export const selectLocationIds = state => state.locations.allIds
export const selectIsLocationVisible = (state, id) =>
  state.locations.isVisibleById[id]
export const selectLocation = (state, id) => state.locations.allById[id]

export const selectAllSelected = createSelector(
  selectLocationIds,
  selectVisibleLocationIds,
  (allIds, selectedIds) =>
    allIds.sort().join(',') === selectedIds.sort().join(',')
)

export const selectAllHidden = createSelector(
  selectVisibleLocationIds,
  selectedIds => selectedIds.length === 0
)

export const selectMardtDomain = createSelector(selectReports, reports => {
  if (!reports) {
    return [0, 0]
  }

  const hasNegative = reports.some(r => r.mardt < 0)
  const maxAbsValue = Math.max(...reports.map(r => Math.abs(r.mardt)))
  const borderValue = Math.ceil(maxAbsValue / 100) * 100
  return [hasNegative ? -borderValue : 0, borderValue]
})
