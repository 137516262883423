import React from 'react'
import ReportHeader from 'components/ReportHeader'
import { connect } from 'react-redux'
import Button from 'components/Button'
import Icon from 'components/Icon'
import PropTypes from 'prop-types'
import { selectNavigationRange } from 'containers/Navigation/selectors'
import { selectVisibleLocationIds } from 'reports/tpm/selectors'
import { FormattedMessage } from 'react-intl'

const Header = ({ locationIds, timePeriod }) => (
  <FormattedMessage id='reports.tpm.name'>
    {text => (
      <ReportHeader name={text} reverseNav>
        <div style={{ marginLeft: 10 }}>
          <a
            href={
              '/reports/tpm/xlsx.xlsx?location_ids=' + locationIds.join(',') +
              '&time_period=' + JSON.stringify(timePeriod)
            }
            format='xlsx'
            style={{ display: 'contents' }}
            target='_blank'
          >
            <Button color='success'>
              <Icon name='download' />
              &nbsp; XLS
            </Button>
          </a>
        </div>
      </ReportHeader>
    )}
  </FormattedMessage>
)

Header.propTypes = {
  locationIds: PropTypes.array,
  timePeriod: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  locationIds: selectVisibleLocationIds(state),
  timePeriod: selectNavigationRange(state)
})

export default connect(mapStateToProps)(Header)
