import React from 'react'
import { selectReports, selectMardtDomain } from 'reports/tpm/selectors'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Line,
  Cell,
  Tooltip
} from 'recharts'
import moment from 'moment'
import TPMGraphLabel from './TPMGraphLabel'

const monthFormatter = (month) => moment(month).format('MMM YYYY')
const percentageFormatter = (val) => `${val.toFixed(2)} %`

const BAR_SIZE = 40

const GraphTooltip = ({ payload }) => {
  if (!payload || !payload[0]) {
    return null
  }

  const { month, mardt, threshold } = payload[0].payload

  return (
    <div className='report-graph-tooltip' style={{ minWidth: 200 }}>
      <div className='report-graph-tooltip__content'>
        <h4 className='report-graph-tooltip__header'>
          {monthFormatter(month)}
        </h4>
      </div>

      <div className='report-graph-tooltip__footer'>
        <div style={{ display: 'flex' }}>
          <div className='recharts-custom-tooltip__label'>
            <FormattedMessage id='reports.tpm.mardt.tooltip.mardt' />
            &nbsp;
          </div>
          <div className='recharts-custom-tooltip__value'>
            {percentageFormatter(mardt)}
          </div>
        </div>
        <br />
        <div style={{ display: 'flex' }}>
          <div className='recharts-custom-tooltip__label'>
            <FormattedMessage id='reports.tpm.mardt.tooltip.threshold' />
            &nbsp;
          </div>
          <div className='recharts-custom-tooltip__value'>
            {percentageFormatter(threshold)}
          </div>
        </div>
      </div>
    </div>
  )
}

GraphTooltip.propTypes = {
  payload: PropTypes.array
}

const TPMGraphMARDT = ({ data, intl, mardtDomain }) => {
  return (
    <div>
      <h2>{intl.formatMessage({ id: 'reports.tpm.mardt' })}</h2>
      <div style={{ width: '100%', height: 500, fontFamily: 'monospace' }}>
        <ResponsiveContainer>
          <ComposedChart
            syncId='tpm'
            width={600}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 20,
              bottom: 120,
              left: 20
            }}
            barGap={0}
          >
            <CartesianGrid stroke='#f5f5f5' />
            <ReferenceLine y={0} stroke='#000' yAxisId='left' />
            <XAxis interval={0} dataKey='month' tick={<TPMGraphLabel />} />
            <YAxis
              dataKey='mardt'
              yAxisId='left'
              orientation='left'
              domain={mardtDomain}
            />
            <Bar
              yAxisId='left'
              dataKey='mardt'
              barSize={BAR_SIZE}
              fill='#2F81B7'
            >
              {data &&
                data.map((entry) => (
                  <Cell
                    key={JSON.stringify(entry)}
                    fill={
                      entry.mardt >= entry.threshold ? '#30AD64' : '#BF3A31'
                    }
                  />
                ))}
            </Bar>
            <Line
              dataKey='threshold'
              animationDuration={500}
              yAxisId='left'
              type='monotone'
              stroke='#FFB1A1'
              strokeDasharray='10 5'
              strokeWidth={2}
            />
            <Tooltip content={<GraphTooltip />} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

TPMGraphMARDT.propTypes = {
  data: PropTypes.array,
  intl: intlShape,
  mardtDomain: PropTypes.array
}

const mapStateToProps = (state) => ({
  data: selectReports(state),
  mardtDomain: selectMardtDomain(state)
})

export default compose(connect(mapStateToProps), injectIntl)(TPMGraphMARDT)
