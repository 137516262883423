import { connect } from 'react-redux'
import { selectLocationIds } from 'reports/tpm/selectors'
import TPMReportLocationToggle from 'reports/tpm/TPMReportLocationToggle'
import ProductionReportLocations from 'components/ProductionReportLocations'
import TPMReportLocationSelectAll from './TPMReportLocationsSelectAll'
import TPMReportLocationSelectNone from './TPMReportLocationsSelectNone'

const mapStateToProps = (state) => ({
  ids: selectLocationIds(state),
  Toggle: TPMReportLocationToggle,
  SelectAll: TPMReportLocationSelectAll,
  SelectNone: TPMReportLocationSelectNone
})

export default connect(mapStateToProps)(ProductionReportLocations)
