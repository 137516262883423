import { all, fork, call, put, select, takeLatest, race, take, delay } from 'redux-saga/effects'
import moment from 'moment-timezone'
import api from 'lib/api/client'
import Actions, {
  startInit,
  finishInit,
  receiveReports,
  receiveLocations
} from 'reports/tpm/actions'
import heartbeat from 'lib/saga/heartbeat'
import {
  selectToken,
  selectTimezone,
  selectVisibleLocationIds
} from 'reports/tpm/selectors'
import { SET_NAVIGATION, setNavigationDate, configureNavigation } from 'containers/Navigation/actions'
import { selectNavigationRange } from 'containers/Navigation/selectors'
import { TYPE_MONTHLY_RANGE } from 'containers/Navigation/const'

const AUTORELOAD_INTERVAL = 60 * 1000

function * init () {
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)

  yield put(startInit())

  yield put(configureNavigation(TYPE_MONTHLY_RANGE))
  yield put(setNavigationDate(moment.tz({ hour: 0 }, timezone)))

  const [locations] = yield all([
    call(api.get, '/locations', { token })
  ])

  yield all([
    put(receiveLocations(locations))
  ])

  yield put(finishInit())
}

function * reports () {
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)
  const timeParams = yield select(selectNavigationRange, timezone)
  const locations = yield select(selectVisibleLocationIds)
  const params = { ...timeParams, locations: locations.join(',') }

  const reports = yield call(api.get, '/reports/tpm', { params, token })

  yield put(receiveReports(reports))
}

function * reload () {
  let result

  while (true) {
    result = yield race({
      action: take([
        Actions.SHOW_LOCATION,
        Actions.HIDE_LOCATION,
        SET_NAVIGATION
      ]),
      timeout: delay(AUTORELOAD_INTERVAL)
    })

    if (result.timeout) {
      yield call(reports)
    }
  }
}

export default function * root () {
  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'Reports:TPM')
  }

  yield call(init)
  yield call(reports)
  yield takeLatest([
    Actions.SHOW_LOCATION,
    Actions.HIDE_LOCATION,
    Actions.SHOW_ALL,
    Actions.HIDE_ALL,
    SET_NAVIGATION
  ], reports)

  yield fork(reload)
}
